.label-search {
    margin-bottom: -17px;
    margin-top: 0;
    color: #495057;
  }
  .vehicle-title{
    width: 100%;
  }

.vehicles__filter__section{
  display: flex;
  justify-content: flex-end;
}
